<template>
    <MenuBar /> 
  <div class="swiper-container">
    <swiper :slides-per-view="1" :pagination="{ clickable: true }" :navigation="true" :loop="true">
      <swiper-slide v-for="(slide, index) in slides" :key="index">
        <img :src="slide.image" :alt="'Slide ' + (index + 1)" class="d-block w-100"/>
        <div class="swiper-caption">
          <h3>{{ slide.caption }}</h3>
          <p>{{ slide.text }}</p>
        </div>
      </swiper-slide>
      <!-- Add pagination -->
      <div class="swiper-pagination"></div>
      <!-- Add navigation -->
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>

      <div class="view-top-bar">
        <div class="back-icon" @click="gotoHome"><i class="fa fa-arrow-left"></i></div>
        <div class="save-icon"><i class="fa fa-heart"></i></div>
      </div>

    </swiper>
  </div>

  <section class="section-profile">
    <div class="category-view">
      <div class="category">House</div>
    </div>
    <div class="title">WoodLand PRorperty</div>
    <div class="location">
      <i class="fa fa-map-marker"></i> Near to Ramseen road, Shree Colony,Bhinmal, Jalore
    </div>
    <div class="amount">$ 2000 Lack</div>
    
    <div class="amenities-header">
      <div class="title">Details</div>
      <div class="p-2">भीनमाल राजस्थान के जालोर जिले में स्थित एक ऐतिहासिक नगर है। यह प्राचीन शहर व्यापार और सांस्कृतिक धरोहर के लिए प्रसिद्ध है। अगर आप भीनमाल में संपत्ति निवेश करने की सोच रहे हैं, तो यहाँ कुछ महत्वपूर्ण बातें हैं:</div>
    </div>
    <div class="amenities-header">
      <div class="title">Amenities</div>

      <div class="amenities-bar">
        
        <div class="amenities-box">
          <i class="fa fa-flag"></i>
          <div>3 Beds</div>
        </div>

        <div class="amenities-box">
          <i class="fa fa-flag"></i>
          <div>3 Beds</div>
        </div>

        <div class="amenities-box">
          <i class="fa fa-flag"></i>
          <div>3 Beds</div>
        </div>

        <div class="amenities-box">
          <i class="fa fa-flag"></i>
          <div>3 Beds</div>
        </div>

      </div>

    </div>

    <div class="amenities-header">
      <div class="title">Dealer</div>
      <div class="p-2 send-message-btn">
        <div>Send WhatsApp Message</div>
      </div>
    </div>

  </section>

  <section class="section-property">
    <div class="section-header">
      <div class="title">Recommended</div>
      <div class="sea_all">See all</div>
    </div>
    <!--  New Matches profile -->
    <div class="pull-container">
      <div class="pull-scroller">
        <div class="pull-swap">
          
          <div class="pull-swap-card"  >
            <div class="pull-swap-card-img">
              <img src="/img/property/1.png" >
            </div>
            <div class="pull-swap-card-body">
              <div class="category">Building</div>
              <div class="title">Woodland Buiding</div>
              <div class="location">
                <i class="fa fa-map-marker"></i> Bhinmal, Jalore
              </div>
              <div class="amount">
                $ 2000 Lack
              </div>
            </div>
          </div>

          <div class="pull-swap-card"  >
            <div class="pull-swap-card-img">
              <img src="/img/property/1.png" >
            </div>
            <div class="pull-swap-card-body">
              <div class="category">Building</div>
              <div class="title">Woodland Buiding</div>
              <div class="location">
                <i class="fa fa-map-marker"></i> Bhinmal, Jalore
              </div>
              <div class="amount">
                $ 2000 Lack
              </div>
            </div>
          </div>

          <div class="pull-swap-card"  >
            <div class="pull-swap-card-img">
              <img src="/img/property/1.png" >
            </div>
            <div class="pull-swap-card-body">
              <div class="category">Building</div>
              <div class="title">Woodland Buiding</div>
              <div class="location">
                <i class="fa fa-map-marker"></i> Bhinmal, Jalore
              </div>
              <div class="amount">
                $ 2000 Lack
              </div>
            </div>
          </div>

          <div class="pull-swap-card"  >
            <div class="pull-swap-card-img">
              <img src="/img/property/1.png" >
            </div>
            <div class="pull-swap-card-body">
              <div class="category">Building</div>
              <div class="title">Woodland Buiding</div>
              <div class="location">
                <i class="fa fa-map-marker"></i> Bhinmal, Jalore
              </div>
              <div class="amount">
                $ 2000 Lack
              </div>
            </div>
          </div>
          <div class="pull-swap-card"  >
            <div class="pull-swap-card-img">
              <img src="/img/property/1.png" >
            </div>
            <div class="pull-swap-card-body">
              <div class="category">Building</div>
              <div class="title">Woodland Buiding</div>
              <div class="location">
                <i class="fa fa-map-marker"></i> Bhinmal, Jalore
              </div>
              <div class="amount">
                $ 2000 Lack
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </section>

  <section class="section-property"></section>
  
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import MenuBar from './layout/particle/MenuBar.vue';
export default {
  components: {
     MenuBar,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      slides: [
        {
          image: '/img/property/2.png'
        },
        {
          image: '/img/property/3.png'
        },
        {
          image: '/img/property/4.png'
        }
      ]
    };
  },
  methods:{
    gotoHome(){
      this.$router.push('/home');
    }
  },
};
</script>

<style scoped>
.swiper-container{
  height: 10%;
}
.swiper{
    height: 285px;
}
.w-100{
  height: 100%;
}
.view-top-bar{
    position: fixed;
    top: 0;
    z-index: 1;
    margin: 8%;
    width: 84%;
}
.view-top-bar .back-icon{
  padding: 8px 12px;
    background: white;
    width: fit-content;
    border-radius: 100px;
    float: left;
}
.save-icon{
  padding: 8px 12px;
    background: white;
    width: fit-content;
    border-radius: 100px;
    float: right;
}
.swiper-button-next{
  display: none !important;
}
.swiper-button-prev{
 display: none !important;
}
</style>
