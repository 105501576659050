<template>

  <MenuBar />    

  <section class="section-top-bar">
    <div class="listing-top-bar">
      <div class="back-icon" @click="goToMenuPage('home')"><i class="fa fa-arrow-left"></i></div>
      <div class="top-status-bar" style="margin-right: 6%;">
        Profile
      </div>
    </div>
  </section>

  <nav class="menu-nav-bar show">
    <div class="menu-wrap-img">
        <img src="/img/app/login.png">
        <div>Vikram Jingar</div>
    </div>
    <div class="profile-menu">
    <h5 @click="goToMenuPage('profileUpdate')"><i class="fa fa-user"></i>Your Profile <span class="en-icon"><i class="fa fa-angle-right"></i></span></h5>
    <h5 @click="goToMenuPage('memberList')"><i class="fa fa-users"></i>Member <span class="en-icon"><i class="fa fa-angle-right"></i></span></h5>
    <h5 @click="goToMenuPage('creditPlan')"><i class="fa fa-users"></i>Credit<span class="en-icon"><i class="fa fa-angle-right"></i></span></h5>
    <h5 @click="goToMenuPage('creditHistory')"><i class="fa fa-users"></i>Hisotry<span class="en-icon"><i class="fa fa-angle-right"></i></span></h5>
    <h5 @click="goToMenuPage('privacyPolicy')"><i class="fa fa-th"></i>Privacy Policy <span class="en-icon"><i class="fa fa-angle-right"></i></span></h5>
    <h5 @click="goToMenuPage('helpCenter')"><i class="fa fa-credit-card"></i>Help Canter<span class="en-icon"><i class="fa fa-angle-right"></i></span></h5>
    <h5 @click="userLogout"><i class="fa fa-sign-out"></i> Logout<span class="en-icon"><i class="fa fa-angle-right"></i></span></h5>

    </div>
  </nav>

</template>


<script>

import MenuBar from '../layout/particle/MenuBar.vue';

export default {
  components: {
    MenuBar
  },
  data(){
    return{
      isProfileNavbarUserName:''
    }
  },
  methods: {
    goToMenuPage(menupage) {
      this.$router.push('/'+menupage);
    },
    userLogout(){
      localStorage.setItem('device_token','');
      localStorage.setItem('user_name','');
      this.$router.push('/splash');
    }
  },
  mounted() {
    this.isProfileNavbarUserName = localStorage.getItem('user_name');
  }
};
</script>