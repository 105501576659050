<template>
  <MenuBar />
  <section class="section-top-bar">

    <div class="listing-top-bar">
      <div class="back-icon" @click="gotoHome"><i class="fa fa-arrow-left"></i></div>
      <div class="top-status-bar">
        Help Center 
      </div>
    </div>
  </section>

  <section class="section-property listing-result-bar">
  </section>

  <section style="margin-top: 25%;"></section>  
</template>


<script>
import MenuBar from '../layout/particle/MenuBar.vue';
export default {
  components: {
    MenuBar
  },
  data() {
    return {
      topUserName:''
    };
  },
  methods:{
    gotoProfile(){
      this.$router.push('/profile/1');
    },
    gotoHome(){
      this.$router.push('/profileUser');
    }
  },
  mounted() {
    this.topUserName = localStorage.getItem('user_name');
  }
};
</script>