<style>
	@import "~bootstrap/dist/css/bootstrap.min.css";
</style>
<template>
		<router-view></router-view>
</template>

<script>

import '@fortawesome/fontawesome-free/css/all.css'; // Ensure you are importing the CSS file
import '@custom/custom.css';
import '@custom/new_custom.css';

export default {
  
};

</script>