<template>
    <MenuBar />    

    <div class="spinner-border spinner-custom" :class="{ 'show': isSpinnerOpen }" role="status"><span class="visually-hidden">Loading...</span></div>


    <div class="plan-list">
    
    <div v-if="rec != '' ">
      <div class="card" v-for="val in rec" :key="val.id" >
        <div class="card-header  text-bg-primary">{{val.get_details.plan_title}}
          <span class="float-end">{{ val.by_date }}</span>
        </div>
        <div class="card-body">
          <div class="plan-text">
            <table class="table text-center">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Type</th>
                  <th>Total</th>
                  <th>Completed</th>
                  <th>Pending</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>{{val.get_details.view_text}}</td>
                  <td>{{val.get_details.total_view}}</td>
                  <td>{{val.total_view}}</td>
                  <td>{{val.get_details.total_view-val.total_view}}</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>{{val.get_details.save_text}}</td>
                  <td>{{val.get_details.total_save}}</td>
                  <td>{{val.total_save}}</td>
                  <td>{{val.get_details.total_save - val.total_save}}</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>{{val.get_details.ping_text}}</td>
                  <td>{{val.get_details.total_ping}}</td>
                  <td>{{val.total_ping}}</td>
                  <td>{{val.get_details.total_ping-val.total_ping}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="text-center">
        <i class="fa fa-question" style="font-size: 5em;"></i>
        <div><b>No By Any Plan</b> </div>
      </div>
    </div>
      
    </div>

    <section style="margin-top: 25%;"></section>  
</template>


<script>

import MenuBar from '../layout/particle/MenuBar.vue';


import axios from 'axios';

export default {
  components: {
    MenuBar
  },
  data(){
    return{
      isSpinnerOpen:false,
      rec:null
    }
  },
  methods:{
    async get_plan_details(){
      this.isSpinnerOpen = true;

      try {
        const response = await axios.get('/api/get_user_by_plan',{
           headers: {
            'Authorization': 'Bearer YOUR_TOKEN',
            'device_token': localStorage.getItem('device_token')
          }
        });
        
        if(response.data.status == 200){
            
            if(response.data.data){
              this.rec = response.data.data;
            }else{
              this.rec = '';
            }
            this.isSpinnerOpen = false;

        }else if(response.data.status == 400){

          alert(response.data.massage);
          this.$router.push('/splash');

        }else{
          this.isSpinnerOpen = false;
          alert(response.data.massage);
        }
      } catch (error) {
        
        this.isSpinnerOpen = false;
        alert(error);
      }
    }
  },
  mounted() {
    this.get_plan_details();
  }
};
</script>