<template>
    <MenuBar />    

    <div class="spinner-border spinner-custom" :class="{ 'show': isSpinnerOpen }" role="status"><span class="visually-hidden">Loading...</span></div>

    <div class="d-flex justify-content-center align-items-center w-100 toast-header">
      <div class="toast" :class="{ 'show': isToasterOpen }">
        <div class="toast-body">{{isToasterError}}</div>
      </div>
    </div>

    <section class="section-top-bar">

    <div class="listing-top-bar">
      <div class="back-icon" @click="goToMenuPage('profileUser')"><i class="fa fa-arrow-left"></i></div>
      <div class="top-status-bar" style="margin-right: 6%;">
        Your Profile
      </div>
    </div>
  </section>



<form class="update-form"  @submit.prevent="submitForm">
<nav class="menu-nav-bar show">
  <div class="menu-wrap-img">
      <img src="/img/app/login.png">
       <input class="update_input-pic" type="file" @change="handleFileUpload" accept="image/*" >
  </div>
	
	<div class="profile-menu">
	
    
    <label class="login-lable">Phone No.</label>
    <input class="mobile_input-2" v-model="mobileNumber" required readonly="">

    <label class="login-lable">Your Name</label>
    <input type="text" required="" class="mobile_input-2"  v-model="form.user_name">

    <label class="login-lable">Address</label>
    <input type="text" required="" class="mobile_input-2" v-model="form.address">

    <button class="send-btn-2 update-btn" type="submit"><i class="fa fa-save"></i> Update</button>
	</div>
</nav>
</form>
</template>


<script>
import MenuBar from '../layout/particle/MenuBar.vue';

import axios from 'axios';
export default {
  components: {
    MenuBar
  },
  data() {
    return {
      isSpinnerOpen:false,
      isToasterOpen:false,
      isToasterError:'',
      form:{
        user_name:localStorage.getItem('user_name'),
        address:localStorage.getItem('address'),
      }
    };
  },
  methods: {
    goToMenuPage(menupage) {
      this.$router.push('/'+menupage);
    },
    async submitForm() {
      
      const formData = new FormData();
      formData.append('user_name', this.form.user_name);
      formData.append('address', this.form.address);

      try {
        this.isSpinnerOpen = true;
        const response = await axios.post('/api/update_user_profile', formData,{
           headers: {
            'Content-Type': 'multipart/form-data',
            'device_token': localStorage.getItem('device_token')
          }
        });

        if(response.data.status == 200){
            
            this.isSpinnerOpen = false;

            localStorage.setItem('user_name',this.form.user_name);

            this.$router.push('/profileUpdate');
            this.showToaster(response.data.massage);

        }else if(response.data.status == 400){

          this.showToaster(response.data.massage);
          this.$router.push('/splash');

        }else{
          this.isSpinnerOpen = false;
          this.showToaster(response.data.massage);
        }


      } catch (error) {
        
        this.isSpinnerOpen = false;
        this.showToaster(error);
      }
    },
    async get_details() {
    
      this.isSpinnerOpen = true;

      try {
        const response = await axios.get('/api/get_user_details',{
           headers: {
            'Authorization': 'Bearer YOUR_TOKEN',
            'device_token': localStorage.getItem('device_token')
          }
        });
        
        if(response.data.status == 200){
          
            this.mobileNumber = response.data.data.mobile_number;
            this.form.user_name = response.data.data.name;
            this.form.address = response.data.data.address;

            localStorage.setItem('user_name',response.data.data.name);
            this.isSpinnerOpen = false;

        }else if(response.data.status == 400){

          this.showToaster(response.data.massage);
          this.$router.push('/splash');

        }else{
          this.isSpinnerOpen = false;
          this.showToaster(response.data.massage);
        }

      } catch (error) {
        
        this.isSpinnerOpen = false;
        this.showToaster(error);
      }
    },
    showToaster(msg){
      this.isToasterOpen = true;
      this.isToasterError = msg;
      setTimeout(() => {
        this.isToasterOpen = false;
      }, 2000);
    }
  },
  mounted() {
    this.get_details();
  }
};
</script>