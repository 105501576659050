<template>
  <section class="screen_one">
    <i class="fa fa-building"></i>
    <div class="title">JINGAR Milan</div>
  </section>

</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      isSpinnerOpen:false,
      isToasterOpen:false,
      isToasterError:''
    };
  },
  methods: {
    check_token_in_local_server(){
      //localStorage.setItem('device_token','490637012055');
      //localStorage.setItem('user_name','');
      var tokan_number  = localStorage.getItem('device_token');
      
      if(tokan_number != 0 && tokan_number != null){
        this.check_token_on_server(tokan_number);
      }else{
        setTimeout(() => {
           this.$router.push('/splash2');
        }, 1000);
      }

    },
    async check_token_on_server(tokan_number) {
      
      this.isSpinnerOpen = true;

      try {
        const response = await axios.get('/api/verifytoken',{
           headers: {
            'Authorization': 'Bearer YOUR_TOKEN',
            'device_token': tokan_number
          }
        });
        
        if(response.data.status == 200){
          
          localStorage.setItem('user_name',response.data.user_name);
          this.isSpinnerOpen = false;
          this.$router.push('/home');

        }else{

          localStorage.setItem('device_token','');
          this.isSpinnerOpen = false;
          this.$router.push('/splash2');

        }
      } catch (error) {
        
        localStorage.setItem('device_token','');
        this.isSpinnerOpen = false;
        this.$router.push('/splash2');
      }
    }
  },
  mounted() {
    this.check_token_in_local_server();
    
  }
};
</script>