<template>
  <MenuBar />
  <section class="section-top-bar">

    <div class="listing-top-bar">
      <div class="back-icon" @click="gotoHome"><i class="fa fa-arrow-left"></i></div>
      <div class="top-status-bar">
        Privacy Policy 
      </div>
    </div>
  </section>

  <section class="section-property listing-result-bar">
    <p class="p-2">
      Here’s a sample Privacy Policy for a property dealer app:

---

**Privacy Policy**

**Effective Date:** [Insert Date]

**1. Introduction**
Welcome to [Your Property Dealer App] ("we", "our", "us"). This Privacy Policy explains how we collect, use, disclose, and protect your information when you use our mobile application (the "App") and our services.

**2. Information We Collect**
We collect various types of information in connection with the services we provide, including:
- **Personal Information**: Name, email address, phone number, address, and other contact details.
- **Property Details**: Information about properties you list or express interest in.
- **Usage Data**: Information about how you use the App, including IP address, browser type, and access times.
- **Device Information**: Information about the device you use to access the App, including device ID, operating system, and mobile network information.

**3. How We Use Your Information**
We use the information we collect to:
- Provide, operate, and maintain the App.
- Improve, personalize, and expand our services.
- Communicate with you, including sending updates and promotional materials.
- Process transactions and send notifications related to your property dealings.
- Monitor and analyze usage and trends to improve user experience.
- Prevent fraudulent transactions and enhance the security of our App.

**4. Sharing Your Information**
We may share your information with:
- **Service Providers**: Third parties that assist us in providing and improving our services.
- **Legal Authorities**: When required by law or in response to legal requests.
- **Business Transfers**: In the event of a merger, acquisition, or sale of assets, your information may be transferred to the new owner.

**5. Security of Your Information**
We implement security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is completely secure, so we cannot guarantee its absolute security.

**6. Your Data Protection Rights**
Depending on your location, you may have the following rights regarding your personal information:
- The right to access – You have the right to request copies of your personal data.
- The right to rectification – You have the right to request that we correct any information you believe is inaccurate or complete information you believe
    </p>
  </section>

  <section style="margin-top: 25%;"></section>  
  
</template>


<script>
import MenuBar from '../layout/particle/MenuBar.vue';
export default {
  components: {
    MenuBar
  },
  data() {
    return {
      topUserName:''
    };
  },
  methods:{
    gotoProfile(){
      this.$router.push('/profile/1');
    },
    gotoHome(){
      this.$router.push('/profileUser');
    }
  },
  mounted() {
    this.topUserName = localStorage.getItem('user_name');
  }
};
</script>