<template>
  <MenuBar /> 
    <div class="spinner-border spinner-custom" :class="{ 'show': isSpinnerOpen }" role="status"><span class="visually-hidden">Loading...</span></div>

    <div class="d-flex justify-content-center align-items-center w-100 toast-header">
      <div class="toast" :class="{ 'show': isToasterOpen }">
        <div class="toast-body">{{isToasterError}}</div>
      </div>
    </div>

  <section class="section-top-bar">

    <div class="listing-top-bar">
      <div class="back-icon" @click="gotoHome"><i class="fa fa-arrow-left"></i></div>
      <div class="top-status-bar">Add New Member</div>
    </div>
  </section>


<div class="jm-cart-section pb-0" style="margin-bottom: 100px">
  <form class="add-member-form" @submit.prevent="submitForm">
  
  <div class="add-member-form-box">
    <h3 class="form-coption">Basic Details</h3>
    <div>
      <label>किस के प्रोफाइल बना रहे हो ?</label>
      <select class="search-select" v-model="form.profile_who" id="profile_who" >
        <option value="0">Select</option>
        <option  v-for="val in profile_who" :key="val.id" :value="val.id">{{val.name}}</option>
      </select>
    </div>
    <div>
      <label>नाम लिखे <span class="error">*</span></label>
      <input class="search-select"  type="" v-model="form.name" id="name" >
    </div>
    <div>
      <label>Male/Female?</label>
      <select class="search-select" v-model="form.gender" id="gender" >
        <option value="0">Select</option>
        <option value="Male">Male</option>
        <option value="Female">Female</option>
      </select>
    </div>
    <div>
      <label>गोत्र <span class="error">*</span></label>
      <select class="search-select" v-model="form.gotra" id="gotra" >
        <option value="0">Select</option>
        <option  v-for="val in gotra" :key="val.id" :value="val.id">{{val.name}}</option>
      </select>
    </div>
    <div>
      <label>जन्म दिनांक <span class="error">*</span></label>
      <input class="search-select"  type="date" v-model="form.dob" id="dob" >
    </div>
    <div>
      <label>जन्म समय</label>
      <input class="search-select"  type="time" v-model="form.dob_time" id="dob_time" >
    </div>
    <div>
      <label>जन्म स्थान</label>
      <input class="search-select"  type="text" v-model="form.dob_place" id="dob_place" >
    </div>
    <div>
      <label>हाइट </label>
      <input class="search-select"  type="text" v-model="form.height" id="height">
    </div>
    <div>
      <label>मांगलिक ? </label>
      <select class="search-select" v-model="form.manglik" id="manglik">
        <option value="0">Select</option>
        <option value="No">No</option>
        <option value="Yes">Yes</option>
      </select>
    </div>
    <div>
      <label>Other Details</label>
      <input class="search-select" type="text"  v-model="form.other_details" id="other_details">
    </div>
    <div>
      <label>वर्तमान स्थिति ? </label>
      <select class="search-select" v-model="form.relation" id="relation">
        <option value="0">Select</option>
        <option  v-for="val in raletion" :key="val.id" :value="val.id">{{val.name}}</option>
      </select>
    </div>
    <div>
      <label>Current Work ? </label>
      <select class="search-select" v-model="form.current_work" id="current_work">
        <option value="0">Select</option>
        <option  v-for="val in working" :key="val.id" :value="val.id">{{val.name}}</option>
      </select>
    </div>
    <div>
      <label>Current Work Details </label>
      <input class="search-select"  type="text" v-model="form.current_work_text" id="current_work">
    </div>
    <div>
      <label>Monthly Salary </label>
      <input class="search-select"  type="number" v-model="form.salary" id="salary">
    </div>
  </div>

  <div class="add-member-form-box">
    <h3 class="form-coption">Educational Details</h3>
    <div>
      <label>Education <span class="error">*</span></label>
      <select class="search-select"  v-model="form.education" id="education">
        <option value="0">Select</option>
        <option  v-for="val in education" :key="val.id" :value="val.id" >{{val.name}}</option>
      </select>
    </div>
    <div>
      <label>Other Education </label>
      <input class="search-select"  type="text"  v-model="form.education_text" id="education_text">
    </div>
  </div>

  <div class="add-member-form-box">
    <h3 class="form-coption">Address Details</h3>
    <div>
      <label>राज्य <span class="error">*</span></label>
      <select class="search-select"  v-model="form.state" id="state" @change="get_district">
        <option value="0">Select State</option>
        <option  v-for="val in state" :key="val.id" :value="val.id" >{{val.name}}</option>
      </select>
    </div>
    <div>
      <label>जिला <span class="error">*</span></label>
      <select class="search-select"  v-model="form.district" id="district" >
        <option value="0">Select District</option>
        <option  v-for="val in district" :key="val.id" :value="val.id" >{{val.name}}</option>
      </select>
    </div>
    <div>
      <label>शहर / गांव <span class="error">*</span></label>
      <input class="search-select"  type=""  v-model="form.city" id="city">
    </div>
    <div>
      <label>Address <span class="error">*</span></label>
      <textarea class="search-select"  v-model="form.address" id="address"></textarea>
    </div>
  </div>

  <div class="add-member-form-box">
    <h3 class="form-coption">Family Details</h3>
    <div>
      <label>पिता जी का नाम <span class="error">*</span></label>
      <input class="search-select"  type=""  v-model="form.father_name" id="father_name">
    </div>
    <div>
      <label>पिता जी क्या काम करते हे </label>
      <input class="search-select"  type=""  v-model="form.father_work" id="father_work">
    </div>
    <div>
      <label>माता जी का नाम <span class="error">*</span></label>
      <input class="search-select"  type=""  v-model="form.mother_name" id="mother_name">
    </div>
    <div>
      <label>आपके माता जी क्या काम करते हे </label>
      <input class="search-select"  type=""  v-model="form.mother_work" id="mother_work">
    </div>
    <div>
      <label>आपके माता जी की गोत्र <span class="error">*</span></label>
      <select class="search-select"  v-model="form.mother_gotra" id="mother_gotra">
        <option value="0">Select</option>
        <option  v-for="val in gotra" :key="val.id" :value="val.id"  >{{val.name}}</option>
      </select>
    </div>
    <div>
      <label>भाई</label>
      <input class="search-select"  type="number"  v-model="form.brother" id="brother">
    </div>
    <div>
      <label>बहन</label>
      <input class="search-select" type="number"  v-model="form.sister" id="sister">
    </div>
    <div>
      <label>Cotact No.1</label>
      <input class="search-select"  type="number"  v-model="form.mobile_1" id="mobile_1">
    </div>
    <div>
      <label>Contact No.2 </label>
      <input class="search-select"  type="number"  v-model="form.mobile_2" id="mobile_2">
    </div>
  </div>

  <div class="add-member-form-box">
    <h3 class="form-coption">Profile Image (multiple)</h3>
    
    <div>
      <input class="search-select"  type="file" @change="handleFileUpload" accept="image/*" multiple />
    </div>
    <div v-if="validationErrors.length">
        <ul>
          <li v-for="(error, index) in validationErrors" :key="index">{{ error }}</li>
        </ul>
      </div>
  </div>

    <div class="text-center">
      <button class="send-btn-2 w-85 " type="submit"><i class="fa fa-save"></i> Submit</button>
    </div>
  </form>
</div>

</template>


<script>

import MenuBar from '../layout/particle/MenuBar.vue';

import axios from 'axios';
export default {
  components: {
    MenuBar
  },
  data() {
    return {
      rec:null,
      profile_who:null,
      gotra:null,
      raletion:null,
      working:null,
      education:null,
      state:null,
      district:null,
      isSpinnerOpen:false,
      isToasterOpen:false,
      isToasterError:'',
      selectedFiles: [],
      uploadProgress: 0,
      validationErrors: [],
      form:{
        profile_who:1,
        name:'VIKRAM',
        gender:'Male',
        gotra:1,
        dob:'1990-01-01',
        dob_time:'12:33',
        dob_place:'BHINMAL',
        height:5.5,
        manglik:'No',
        other_details:'',
        relation:1,
        current_work:0,
        current_work_text:'',
        salary:'',
        education:1,
        education_text:'COMPUTER',
        state:0,
        district:0,
        city:'BHINMAL',
        address:'JINGAR',
        father_name:'PRABHU LAL',
        father_work:'BUSINESS',
        mother_name:'SEETA DEVI',
        mother_work:'HOUSE WIFE',
        mother_gotra:1,
        brother:2,
        sister:'',
        mobile_1:'',
        mobile_2:'',
      }
    };
  },
  methods: {
    handleFileUpload(event) {
      this.validationErrors = [];
      this.selectedFiles = Array.from(event.target.files);

      this.selectedFiles.forEach(file => {
        if (!file.type.startsWith('image/')) {
          this.validationErrors.push(`${file.name} is not a valid image file.`);
        }

        if (file.size > 2048 * 1024) { // 2MB limit
          this.validationErrors.push(`${file.name} exceeds the maximum size of 2MB.`);
        }
      });

      if (this.validationErrors.length > 0) {
        this.selectedFiles = []; // Clear selected files if validation fails
      }
    },
    goToProfilePage(id) {
      this.$router.push({ name: 'profile', params: { id: id }});
    },
    goToMemberList() {
       this.$router.push('/memberLists');
    },
    async get_loop_data() {
    
      this.isSpinnerOpen = true;

      try {
        const response = await axios.get('/api/member_add_data',{
           headers: {
            'Authorization': 'Bearer YOUR_TOKEN',
            'device_token': localStorage.getItem('device_token')
          }
        });
        
        if(response.data.status == 200){
          
            this.rec = response.data.data;
            this.profile_who = response.data.data.profile_who;
            this.gotra = response.data.data.gotra;
            this.raletion = response.data.data.raletion;
            this.working = response.data.data.working;
            this.education = response.data.data.education;
            this.state = response.data.data.state;
            
            this.isSpinnerOpen = false;

        }else if(response.data.status == 400){

          this.showToaster(response.data.massage);
          this.$router.push('/splash');

        }else{
          this.isSpinnerOpen = false;
          this.showToaster(response.data.massage);
        }

      } catch (error) {
        
        this.isSpinnerOpen = false;
        this.showToaster(error);
      }
    },
    async submitForm() {
      
      if (this.selectedFiles.length === 0) {
        this.showToaster('Please select image files to upload.');
        return;
      }

      const formData = new FormData();
      formData.append('profile_who', this.form.profile_who);
      formData.append('name', this.form.name);
      formData.append('gender', this.form.gender);
      formData.append('gotra', this.form.gotra);
      formData.append('dob', this.form.dob);
      formData.append('dob_time', this.form.dob_time);
      formData.append('dob_place', this.form.dob_place);
      formData.append('height', this.form.height);
      formData.append('manglik', this.form.manglik);
      formData.append('other_details', this.form.other_details);
      formData.append('relation', this.form.relation);
      formData.append('current_work', this.form.current_work);
      formData.append('current_work_text', this.form.current_work_text);
      formData.append('salary', this.form.salary);
      formData.append('education', this.form.education);
      formData.append('education_text', this.form.education_text);
      formData.append('state', this.form.state);
      formData.append('district', this.form.district);
      formData.append('city', this.form.city);
      formData.append('address', this.form.address);
      formData.append('father_name', this.form.father_name);
      formData.append('father_work', this.form.father_work);
      formData.append('mother_name', this.form.mother_name);
      formData.append('mother_work', this.form.mother_work);
      formData.append('mother_gotra', this.form.mother_gotra);
      formData.append('brother', this.form.brother);
      formData.append('sister', this.form.sister);
      formData.append('mobile_1', this.form.mobile_1);
      formData.append('mobile_2', this.form.mobile_2);


      this.selectedFiles.forEach((file, index) => {
        formData.append(`images[${index}]`, file);
      });

      this.isSpinnerOpen = true;

      try {
        const response = await axios.post('/api/add_new_member', formData,{
           headers: {
            'Content-Type': 'multipart/form-data',
            'device_token': localStorage.getItem('device_token')
          }
        });

        if(response.data.status == 200){
            
            this.isSpinnerOpen = false;

            this.showToaster(response.data.massage);
            this.$router.push('/memberList');

        }else if(response.data.status == 400){

          this.showToaster(response.data.massage);
          this.$router.push('/splash');

        }else{
          this.isSpinnerOpen = false;
          this.showToaster(response.data.massage);
        }


      } catch (error) {
        
        this.isSpinnerOpen = false;
        this.showToaster(error);
      }
    },
    async get_district(event) {
      
      const state_id = event.target.value;

      this.isSpinnerOpen = true;

      try {
        const response = await axios.get('/api/get_district/'+state_id,{
           headers: {
            'Authorization': 'Bearer YOUR_TOKEN',
            'device_token': localStorage.getItem('device_token')
          }
        });
        
        if(response.data.status == 200){
            this.district = response.data.data;
            this.isSpinnerOpen = false;
        }else if(response.data.status == 400){
          this.showToaster(response.data.massage);
          this.$router.push('/splash');
        }else{
          this.isSpinnerOpen = false;
          this.showToaster(response.data.massage);
        }
      } catch (error) {
        this.isSpinnerOpen = false;
        this.showToaster(error);
      }
    },
    gotoHome(){
      this.$router.push('/memberList');
    },
    showToaster(msg){
      this.isToasterOpen = true;
      this.isToasterError = msg;
      setTimeout(() => {
        this.isToasterOpen = false;
      }, 2000);
    }
  },
  mounted() {
    this.get_loop_data();
  }
};
</script>