<template>
    <div class="spinner-border spinner-custom" :class="{ 'show': isSpinnerOpen }" role="status"><span class="visually-hidden">Loading...</span></div>

    <div class="d-flex justify-content-center align-items-center w-100 toast-header">
      <div class="toast" :class="{ 'show': isToasterOpen }">
        <div class="toast-body">{{isToasterError}}</div>
      </div>
    </div>

  
  <div class="wrap-template">
        <div class="wrap-home">
          <img src="/img/app/login.png" style="width: 100%;">
          <img src="/img/app/logo.png" style="width: 100%;">
        </div>
        <div class="splash-bar-text"  >
          <h2>97283730308 <span class="error">edit</span></h2>
        </div>

      <div class="login-input-div">
        
        <label class="login-lable">Enter Passcode </label>
      
        <input type="number" class="mobile_input verify_input" placeholder="0000" v-model="mobileNumber"  @input="validateMobileNumber">
        
        <label class="login-lable">Enter Passcode </label>
      
        <input type="number" class="mobile_input verify_input" placeholder="0000" v-model="mobileNumber"  @input="validateMobileNumber">

        <div class="send-btn" style="margin-top: 15%"  @click="getLoginCode()"><i class="fa fa-send"></i> Verify Code</div>
      
        <p v-if="mobileNumberError" class="error">{{ mobileNumberError }}</p>
      </div>

  </div>
  
</template>


<script>
import axios from 'axios';
export default {
  data() {
    return {
      isSpinnerOpen:false,
      isToasterOpen:false,
      isToasterError:'',
      mobileNumber:'',
      mobileNumberError:''
    };
  },
  methods: {
    validateMobileNumber() {
      const phoneRegex = /^[0-3]{4}$/;
      if (!phoneRegex.test(this.mobileNumber)) {
        this.mobileNumberError = 'Invalid OTP.';
      } else {
        this.mobileNumberError = '';
      }
    },
    async getLoginCode() {

      //const phoneRegex = /^[0-3]{4}$/; 
      
      /*if (phoneRegex.test(this.mobileNumber)) {
      }*/
      if (this.mobileNumber > 1) {
      
        this.mobileNumberError = ''; 
        this.isSpinnerOpen = true;
      
        try {
          const response = await axios.get('/api/verify_otp/9782730308/'+this.mobileNumber);
          
          if(response.data.status == 200){
            
           localStorage.setItem('device_token',response.data.data.device_token);
           
           if(response.data.data.name != '' && response.data.data.name != null){
            
            localStorage.setItem('user_name',response.data.data.name);

            this.$router.push('/home');

           }else{
            
              this.$router.push('/loginUserNamePage');

           }

          }else{

            this.isSpinnerOpen = false;
            this.showToaster(response.data.massage);

          }
        } catch (error) {
          
          this.isSpinnerOpen = false;
          
          this.showToaster(error);
        }

      }else{
        this.mobileNumberError = 'Invalid OTP.';
      }
    },
    showToaster(msg){
      this.isToasterOpen = true;
      this.isToasterError = msg;
      setTimeout(() => {
        this.isToasterOpen = false;
      }, 2000);
    }
  }
};
</script>