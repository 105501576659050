<template>
  
  <MenuBar />
  
  <div class="spinner-border spinner-custom" :class="{ 'show': isSpinnerOpen }" role="status"><span class="visually-hidden">Loading...</span></div>

  <div class="d-flex justify-content-center align-items-center w-100 toast-header">
    <div class="toast" :class="{ 'show': isToasterOpen }">
      <div class="toast-body">{{isToasterError}}</div>
    </div>
  </div>
 
  <section class="section-top-bar">
    <div class="listing-top-bar">
      <div class="back-icon" @click="goToMenuPage('home')"><i class="fa fa-arrow-left"></i></div>

      <div class="section-top-bell filter-icon icon-filter" :class="{ 'hide' : isShowFilterIcon }" @click="showFilter">
        <i class="fa fa-filter"></i>
      </div>
      <div class="section-top-bell filter-icon icon-close" :class="{ 'show' : isShowCloseIcon }" @click="hideFilter">
        <i class="fa fa-close"></i>
      </div>
    </div>


    <div class="filter-bar" :class="{ 'show' : isSearchFilterBar }">
    
      <form  @submit.prevent="get_search_listing">


        <div class="filter-box">
          State
          <select v-model="form.state" id="state" @change="get_district">
            <option value="0">ALL</option>
            <option  v-for="val in state" :key="val.id" :value="val.id" >{{val.name}}</option>
          </select>
        </div>
        <div class="filter-box">
          Distric
          <select v-model="form.district" id="district" >
            <option value="0">ALL</option>
            <option  v-for="val in district" :key="val.id" :value="val.id" >{{val.name}}</option>
          </select>
        </div>
        <div class="filter-box">
          Working
          <select v-model="form.working" id="working" >
            <option value="0">ALL</option>
            <option  v-for="val in working" :key="val.id" :value="val.id" >{{val.name}}</option>
          </select>
        </div>
        <div class="filter-box">
          Gotra
          <select  v-model="form.gotra" id="gotra" >
            <option value="0">ALL</option>
            <option  v-for="val in gotra" :key="val.id" :value="val.id"  >{{val.name}}</option>
          </select>
        </div>
        <div class="filter-box">
          Manglik
          <select v-model="form.manglik" id="manglik" >
            <option value="0">ALL</option>
            <option value="No">No</option>
            <option value="Yes">Yes</option>
          </select>
        </div>
        <div class="filter-box">
          Gender
          <select v-model="form.gander" id="gander" >
            <option value="0">ALL</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>
        <div class="filter-box">
          Status
          <select v-model="form.status" id="status" >
            <option value="0">ALL</option>
            <option v-for="val in raletion" :key="val.id" :value="val.id">{{val.name}}</option>
          </select>
        </div>

         <div class="spinner-border spinner-filter" :class="{ 'show': isSpinnerFilterOpen }" role="status"><span class="visually-hidden">Loading...</span></div>

        <div class="filter-box" style="margin-top: 32%;">
          <button type="reset" class="search-close-btn"><i class="fa fa-refresh"></i> Reset </button>
          <button type="submit" class="search-close-btn search-new-btn"><i class="fa fa-search"></i> Apply</button>
        </div>
      </form>

    </div>
  </section>



  <section class="section-property listing-result-bar">
    <div class="section-header section-header2">
      <div class="title">({{total_rec}}) Result Found</div>
      <div class="sea_all">Clear</div>
    </div>

    <div v-if="rec != '' ">
      <div class="pull-container pull-container2">
            
        <div class="swap-card-2"  v-for="val in rec" :key="val.id" >
          <div class="swap-card-img-2"  @click="goToProfilePage(val.id)" >
            <img :src="val.img" >
          </div>
          <div class="swap-card-2-body">
            <div class="title"  @click="goToProfilePage(val.id)" >{{val.name}}</div>
            <div class="category">{{val.work}}</div>
            <div class="category">Gotra : {{val.gotra}}</div>
            <div class="swap-card-2-counter">
              <div class="vister">
                <i class="fa fa-sort-amount-desc"></i>
               : {{val.total_view}}</div>
              <div class="float-r" @click="setUserHistory(val.id)">
                <i class="fa fa-bookmark"></i>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>

    <div v-else>
      <div class="text-center">
        <img src="/img/app/splash.png">
        <div><b>No Data</b> </div>
      </div>
    </div>

  </section>

  <section style="margin-top: 25%;"></section>  

</template>


<script>

import MenuBar from './layout/particle/MenuBar.vue';

import axios from 'axios';
export default {
  components: {
    MenuBar
  },
  data() {
    return {
      isSearchFilterBar:false,
      isSearchTopBar:false,
      isSearchTextBar:false,
      isShowFilterIcon:false,
      isShowCloseIcon:false,
      rec:'',
      state:0,
      district:0,
      working:0,
      gotra:0,
      raletion:0,
      isSpinnerOpen:false,
      isSpinnerFilterOpen:false,
      isToasterOpen:false,
      isToasterError:'',
      total_rec:0,
      form:{
        state:0,
        district:0,
        working:0,
        gotra:0,
        manglik:0,
        gander:0,
        status:0
      }
    };
  },
  methods: {
    showFilter(){
      this.isSearchFilterBar = true;
      this.isSearchTextBar = true;
      this.isSearchTopBar = true;
      this.isShowFilterIcon = true;
      this.isShowCloseIcon = true;
    },
    hideFilter(){
      this.isSearchFilterBar = false;
      this.isSearchTextBar = false;
      this.isSearchTopBar = false;
      this.isShowFilterIcon = false;
      this.isShowCloseIcon = false;
    },
    goToProfilePage(id) {
      this.$router.push({ name: 'profile', params: { id: id }});
    },
    async get_search_listing() {
    
      this.isSpinnerOpen = true;
      this.isSpinnerFilterOpen = true;

      try {
        const response = await axios.post('/api/listing',this.form,{
           headers: {
            'Authorization': 'Bearer YOUR_TOKEN',
            'device_token': localStorage.getItem('device_token')
          }
        });
        
        if(response.data.status == 200){
            
            if(response.data.data){
              this.rec = response.data.data;
              this.total_rec= response.data.total_rec;
            }else{
              this.rec = '';
            }
            this.hideFilter();
            this.isSpinnerOpen = false;
            this.isSpinnerFilterOpen = false;

        }else if(response.data.status == 400){

          this.showToaster(response.data.massage);
          this.$router.push('/splash');

        }else{
          this.isSpinnerOpen = false;
          this.isSpinnerFilterOpen = false;
          this.showToaster(response.data.massage);
        }
      } catch (error) {
        
        this.isSpinnerOpen = false;
        this.isSpinnerFilterOpen = false;
        this.showToaster(error);
      }
    },
    async get_loop_data() {
    
      this.isSpinnerOpen = true;

      try {
        const response = await axios.get('/api/member_add_data',{
           headers: {
            'Authorization': 'Bearer YOUR_TOKEN',
            'device_token': localStorage.getItem('device_token')
          }
        });
        
        if(response.data.status == 200){
          
            this.gotra = response.data.data.gotra;
            this.raletion = response.data.data.raletion;
            this.working = response.data.data.working;
            this.state = response.data.data.state;

            this.isSpinnerOpen = false;


        }else if(response.data.status == 400){

          this.showToaster(response.data.massage);
          this.$router.push('/splash');

        }else{
          this.isSpinnerOpen = false;
          this.showToaster(response.data.massage);
        }
      } catch (error) {
        
        this.isSpinnerOpen = false;
        this.showToaster(error);
      }
    },
    async get_district(event) {
      
      const state_id = event.target.value;

      this.isSpinnerOpen = true;

      try {
        const response = await axios.get('/api/get_district/'+state_id,{
           headers: {
            'Authorization': 'Bearer YOUR_TOKEN',
            'device_token': localStorage.getItem('device_token')
          }
        });
        
        if(response.data.status == 200){
            this.district = response.data.data;
            this.isSpinnerOpen = false;
        }else if(response.data.status == 400){
          this.showToaster(response.data.massage);
          this.$router.push('/splash');
        }else{
          this.isSpinnerOpen = false;
          this.showToaster(response.data.massage);
        }
      } catch (error) {
        this.isSpinnerOpen = false;
        this.showToaster(error);
      }
    },
    async setUserHistory(member_id) {
    
        this.isSpinnerOpen = true;

        const formData = new FormData();
        formData.append('id', member_id);
        formData.append('type', 3);

        this.isToasterOpen = true;

        try {
          const response = await axios.post('/api/set_user_history',formData,{
             headers: {
              'Authorization': 'Bearer YOUR_TOKEN',
              'device_token': localStorage.getItem('device_token')
            }
          });
          
          if(response.data.status == 200){
            
              this.isSpinnerOpen = false;
              this.showToaster(response.data.massage);
            
          }else if(response.data.status == 400){

            this.showToaster(response.data.massage);
            this.$router.push('/splash');

          }else{
            this.isSpinnerOpen = false;
            this.showToaster(response.data.massage);
          }

        } catch (error) {
          
          this.isSpinnerOpen = false;
          this.showToaster(error);
        }

    },
    showToaster(msg){
      this.isToasterOpen = true;
      this.isToasterError = msg;
      setTimeout(() => {
        this.isToasterOpen = false;
      }, 2000);
    }
  },
  mounted() {
    this.get_loop_data();
    //this.showToaster(this.$route.params.slug);
    //this.showToaster(this.$route.params.id);
    //manglik
    //this.form.working = this.$route.params.id;
    //this.form.manglik = 'Yes';
    this.get_search_listing();
  }
};
</script>