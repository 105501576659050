<template>
  
  <MenuBar /> 
  
  <div class="spinner-border spinner-custom" :class="{ 'show': isSpinnerOpen }" role="status"><span class="visually-hidden">Loading...</span></div>

  <div class="d-flex justify-content-center align-items-center w-100 toast-header">
    <div class="toast" :class="{ 'show': isToasterOpen }">
      <div class="toast-body">{{isToasterError}}</div>
    </div>
  </div>

  <section class="section-top-bar">

    <div class="listing-top-bar">
      <div class="back-icon" @click="gotoHome"><i class="fa fa-arrow-left"></i></div>
      <div class="top-status-bar" style="margin-right: 6%;">
        Your Member
      </div>
    </div>
  </section>


  <section class="section-property listing-result-bar">
    <div class="section-header3">
      <div class="title">({{total_rec}}) Member List</div>
      <div class="add-new" @click="addNewMember"><i class="fa fa-plus"></i></div>
    </div>

    <div v-if="rec != '' ">
      <div class="pull-container pull-container2">
            
        <div class="swap-card-2" v-for="val in rec" :key="val.id"  @click="goToDashboard(val.id)" >
          <div class="swap-card-img-2">
            <img :src="val.img" >
          </div>
          <div class="swap-card-2-body">
            <div class="title">{{val.name}}</div>
            <div class="category">Who ? : <span><i class="fa fa-user"></i></span> {{val.profile_who}}</div>
            <div class="category">Profile : {{val.profile}}</div>
            <div class="category">Status : <span class="text-success"><i class="fa fa-circle"></i></span> {{val.status}}</div>
            <div class="category">Total View : <i class="fa fa-eye"></i> {{val.total_view}}</div>
          </div>
        </div>

      </div>
    </div>

    <div v-else>
      <div class="text-center">
        <img src="/img/app/splash.png">
        <div><b>No Data</b> </div>
      </div>
    </div>

  </section>

  <section style="margin-top: 25%;"></section>  
</template>


<script>

import MenuBar from '../layout/particle/MenuBar.vue';

import axios from 'axios';
export default {
  components: {
    MenuBar
  },
  data() {
    return {
      rec:null,
      total_rec:0,
      isSpinnerOpen:false,
      isToasterOpen:false,
      isToasterError:'',
    };
  },
  methods: {
    addNewMember() {
      this.$router.push('/memberAdd');
    },
    goToDashboard(){
      this.$router.push('/memberdash');
    },
    async get_member_listing() {
    
      this.isSpinnerOpen = true;

      try {
        const response = await axios.get('/api/memberlisting',{
           headers: {
            'Authorization': 'Bearer YOUR_TOKEN',
            'device_token': localStorage.getItem('device_token')
          }
        });
        
        if(response.data.status == 200){
          
            if(response.data.data){
              this.rec = response.data.data;
              this.total_rec = response.data.total_rec;
            }else{
              this.rec = '';
            }
            this.isSpinnerOpen = false;

        }else if(response.data.status == 400){

          this.showToaster(response.data.massage);
          this.$router.push('/splash');

        }else{
          this.isSpinnerOpen = false;
          this.showToaster(response.data.massage);
          this.rec = '';
        }
      } catch (error) {
        
        this.isSpinnerOpen = false;
        this.showToaster(error);
      }
    },
    gotoHome(){
      this.$router.push('/profileUser');
    },
    showToaster(msg){
      this.isToasterOpen = true;
      this.isToasterError = msg;
      setTimeout(() => {
        this.isToasterOpen = false;
      }, 2000);
    }
  },
  mounted() {
    this.get_member_listing();
  }
};
</script>