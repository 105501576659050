<template>
<nav class="custome-nav">
    <div class="men-new activem"  @click="goToMenuPage('home')"><i class="fa fa-home"></i>Home</div>
    <div class="men-new" @click="goToSearchPage('all','1')"><i class="fa fa-map-marker"></i>Explore</div>
    <div class="men-new" @click="goToMenuPage('memberAdd')"><i class="fa  fa-plus-circle"></i>ADD</div>
    <div class="men-new" @click="goToMenuPage('saveMember')"><i class="fa fa-heart"></i>Favorite</div>
    <div class="men-new" @click="goToMenuPage('profileUser')"><i class="fa fa-user"></i>Profile</div>
</nav>

</template>

<script>
export default {
  methods: {
    goToMenuPage(menupage) {
      this.$router.push('/'+menupage);
    },
    goToSearchPage(slug,id) {
      this.$router.push({ name: 'listing', params: { slug : slug, id: id }});
    }
  }
};
</script>