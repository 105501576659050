<template>
    <MenuBar />    
    
     <section class="section-top-bar">

    <div class="listing-top-bar">
      <div class="back-icon" @click="gotoHome"><i class="fa fa-arrow-left"></i></div>
      <div class="top-status-bar" style="margin-right: 6%;">
        Account Credit
      </div>
    </div>
  </section>

<div class="spinner-border spinner-custom" :class="{ 'show': isSpinnerOpen }" role="status"><span class="visually-hidden">Loading...</span></div>


    <section class="section-property listing-result-bar plan-list">
      
      <div class="card" v-for="val in rec" :key="val.id" >
        <div class="card-header  text-bg-primary">{{val.plan_title}}</div>
        <div class="card-body">
          <div class="plan-text">
            <ul class="list-group list-group-flush">

              <li class="list-group-item" >{{val.view_text}} : {{val.total_view}}</li>
              <li class="list-group-item" >{{val.save_text}} : {{val.total_save}}</li>
              <li class="list-group-item" >{{val.ping_text}} : {{val.total_ping}}</li>
            </ul>
          </div>
          <div class="plan-text">
            <h1>{{val.per_profile}}/-</h1>
            <div class="plan-tag">{{val.per_profile_text}}</div>
          </div>
          <div class="plan-pay">
            <a href="#" class="btn btn-primary">Pay Now {{val.current_price}}/- <span>{{val.cut_price}}/-</span> </a>
          </div>
        </div>
      </div>
      
    </section>

    <section style="margin-top: 25%;"></section>  
</template>


<script>

import MenuBar from '../layout/particle/MenuBar.vue';
 
import axios from 'axios';

export default {
  components: {
    MenuBar
  },
  data(){
    return{
      isSpinnerOpen:false,
      rec:null
    }
  },
  methods:{
    async get_plan_details(){
      this.isSpinnerOpen = true;

      try {
        const response = await axios.get('/api/get_user_plan',{
           headers: {
            'Authorization': 'Bearer YOUR_TOKEN',
            'device_token': localStorage.getItem('device_token')
          }
        });
        
        if(response.data.status == 200){
            
            if(response.data.data){
              this.rec = response.data.data;
            }else{
              this.rec = '';
            }
            this.isSpinnerOpen = false;

        }else if(response.data.status == 400){

          alert(response.data.massage);
          this.$router.push('/splash');

        }else{
          this.isSpinnerOpen = false;
          alert(response.data.massage);
        }
      } catch (error) {
        
        this.isSpinnerOpen = false;
        alert(error);
      }
    },
    gotoHome(){
      this.$router.push('/profileUser');
    },
  },
  mounted() {
    this.get_plan_details();
  }
};
</script>