<template>
  
  <div class="wrap-template splash2-bg">
    <div class="wrap-home">
      <img src="/img/app/splash.png" style="width: 100%;">
    </div>
  </div>

  <div style="background: var(--bs-light-colors);">
    <div class="splash2-buttom">
      <div class="splash2-buttom-text">
        <span>Discover</span> and Find Your Perfect <span>Dream Partner</span>
      </div>
      <div style="color: var(--bs-light-colors);">
        App to search and discover then most suitable place for you to stay.
      </div>
      <div>
        <button class="splash-get-btn" @click="goToLoginPage">Let's Get Started</button>
      </div>
      <div class="splash2-buttom-text2">
        Already have an account? <u><span>Login</span></u>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  methods: {
    goToLoginPage(){
      this.$router.push('/login');
    }
  }
};
</script>
