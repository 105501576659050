<template>

  <MenuBar />

	<div class="spinner-border spinner-custom" :class="{ 'show': isSpinnerOpen }" role="status"><span class="visually-hidden">Loading...</span></div>
  
  <div class="d-flex justify-content-center align-items-center w-100 toast-header">
    <div class="toast" :class="{ 'show': isToasterOpen }">
      <div class="toast-body">{{isToasterError}}</div>
    </div>
  </div>

    
  <section class="section-top-bar pt-3">
    <div class="section-top-location">
      <div class="toper-logo"><span>Milap</span></div>
    </div>
    <div class="section-top-bell">
      <i class="fa fa-bell"></i>
    </div>
  </section>

  <section class="section-top-search">
    <div class="section-top-search-input">
      <input type="" name="" placeholder="Search by Member ID">
    </div>
    <div class="section-top-search-btn">
      <i class="fa fa-search"></i>
    </div>
  </section>

  <section class="section-category">
    <div class="section-category-box" v-for="val in work_cart.data" :key="val.id"  @click="goToSearchPage('working',val.id)">
      <div class="category-icon">
        <i :class="val.icon"></i>
      </div>
      <div class="title-2">{{val.name}}</div>
    </div>
  </section>

  <section class="section-property">
    <div class="section-header">
      <div class="title">{{swap_cart.title}}</div>
      <div class="sea_all" @click="goToSearchPage('time','latest')">{{swap_cart.see_all}}</div>
    </div>
    <!--  New Matches profile -->
    <div class="pull-container">
      <div class="pull-scroller">
        <div class="pull-swap">
          
          <div class="pull-swap-card" v-for="val in swap_cart.data" :key="val.id"   @click="goToProfilePage(val.id)"  >
            <div class="pull-swap-card-img">
              <img :src="val.img" >
            </div>
            <div class="pull-swap-card-body">
              <div class="title">{{val.name}}</div>
              <div class="category">{{val.work}}</div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>

  <section class="section-property">
    <div class="section-header">
      <div class="title">{{cart_1.title}}</div>
      <div class="sea_all" @click="goToSearchPage('working',cart_1.see_all_link)">{{cart_1.see_all}}</div>
    </div>
    <div class="pull-container">
      <div class="swap-card-2"  v-for="val in cart_1.data" :key="val.id" >
        <div class="swap-card-img-2"  @click="goToProfilePage(val.id)" >
          <img :src="val.img" >
        </div>
        <div class="swap-card-2-body">
          <div class="title"  @click="goToProfilePage(val.id)" >{{val.name}}</div>
          <div class="category">{{val.work}}</div>
          <div class="category">Gotra : {{val.gotra}}</div>
          <div class="swap-card-2-counter">
            <div class="vister">
              <i class="fa fa-sort-amount-desc"></i>
             : {{val.total_view}}</div>
            <div class="float-r" @click="setUserHistory(val.id)">
              <i class="fa fa-bookmark"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section-property">
    <div class="section-header">
      <div class="title">{{cart_2.title}}</div>
      <div class="sea_all"  @click="goToSearchPage('working',cart_2.see_all_link)">{{cart_2.see_all}}</div>
    </div>
    <div class="pull-container">
      <div class="swap-card-2" v-for="val in cart_2.data" :key="val.id" >
        <div class="swap-card-img-2"  @click="goToProfilePage(val.id)">
          <img :src="val.img" >
        </div>
        <div class="swap-card-2-body">
          <div class="title"  @click="goToProfilePage(val.id)" >{{val.name}}</div>
          <div class="category">{{val.work}}</div>
          <div class="category">Gotra : {{val.gotra}}</div>
          <div class="swap-card-2-counter">
            <div class="vister">
              <i class="fa fa-sort-amount-desc"></i>
             : {{val.total_view}}</div>
            <div class="float-r" @click="setUserHistory(val.id)">
              <i class="fa fa-bookmark"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section-property">
    <div class="section-header">
      <div class="title">{{cart_3.title}}</div>
      <div class="sea_all"  @click="goToSearchPage('working',cart_3.see_all_link)">{{cart_3.see_all}}</div>
    </div>
    <div class="pull-container">
      <div class="swap-card-2" v-for="val in cart_3.data" :key="val.id" >
        <div class="swap-card-img-2" @click="goToProfilePage(val.id)" >
          <img :src="val.img" >
        </div>
        <div class="swap-card-2-body">
          <div class="title"  @click="goToProfilePage(val.id)" >{{val.name}}</div>
          <div class="category">{{val.work}}</div>
          <div class="category">Gotra : {{val.gotra}}</div>
          <div class="swap-card-2-counter">
            <div class="vister">
              <i class="fa fa-sort-amount-desc"></i>
             : {{val.total_view}}</div>
            <div class="float-r" @click="setUserHistory(val.id)">
              <i class="fa fa-bookmark"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


  <section style="margin-top: 25%;"></section>    
  
</template>


<script>

import MenuBar from './layout/particle/MenuBar.vue';

import axios from 'axios';

export default {
  components: {
    MenuBar
  },
  data() {
    return {
      rec:null,
      swap_cart:{
        title:'',see_all:'',see_all_link:'',data:null
      },
      work_cart:{
        title:'',see_all:'',see_all_link:'',data:null
      },
      cart_1:{
        title:'',see_all:'',see_all_link:'',data:null
      },
      cart_2:{
        title:'',see_all:'',see_all_link:'',data:null
      },
      cart_3:{
        title:'',see_all:'',see_all_link:'',data:null
      },
      cart_3_counter:0,
      isSpinnerOpen:false,
      isToasterOpen:false,
      isToasterError:'',
    };
  },
  methods: {
    goToSearchPage(slug,id) {
      this.$router.push({ name: 'listing', params: { slug : slug, id: id }});
    },
    goToProfilePage(id) {
      this.$router.push({ name: 'profile', params: { id: id }});
    },
    async get_home_page_listing() {
    
      this.isSpinnerOpen = true;

      try {
        const response = await axios.get('/api/listing_home',{
           headers: {
            'Access-Control-Allow-Origin': '*',
            'device_token': localStorage.getItem('device_token')
          }
        });
                
        if(response.data.status == 200){
          
            this.rec = response.data.data;

            this.swap_cart.title = response.data.data.swap_cart.title;
            this.swap_cart.see_all = response.data.data.swap_cart.see_all;
            this.swap_cart.see_all_link = response.data.data.swap_cart.see_all_link;
            this.swap_cart.data = response.data.data.swap_cart.data;

            this.work_cart.title = response.data.data.work_cart.title;
            this.work_cart.see_all = response.data.data.work_cart.see_all;
            this.work_cart.see_all_link = response.data.data.work_cart.see_all_link;
            this.work_cart.data = response.data.data.work_cart.data;

            this.cart_1.title = response.data.data.cart_1.title;
            this.cart_1.see_all = response.data.data.cart_1.see_all;
            this.cart_1.see_all_link = response.data.data.cart_1.see_all_link;
            this.cart_1.data = response.data.data.cart_1.data;

            this.cart_2.title = response.data.data.cart_2.title;
            this.cart_2.see_all = response.data.data.cart_2.see_all;
            this.cart_2.see_all_link = response.data.data.cart_2.see_all_link;
            this.cart_2.data = response.data.data.cart_2.data;

            this.cart_3.title = response.data.data.cart_3.title;
            this.cart_3.see_all = response.data.data.cart_3.see_all;
            this.cart_3.see_all_link = response.data.data.cart_3.see_all_link;
            this.cart_3.data = response.data.data.cart_3.data;
            

            this.isSpinnerOpen = false;

        }else if(response.data.status == 400){

          this.showToaster(response.data.massage);
          this.$router.push('/splash');

        }else{
          this.isSpinnerOpen = false;
          this.showToaster(response.data.massage);
        }
      } catch (error) {
        
        this.isSpinnerOpen = false;
        this.showToaster(error);
      }
    },
    async setUserHistory(member_id) {
    
        this.isSpinnerOpen = true;

        const formData = new FormData();
        formData.append('id', member_id);
        formData.append('type', 3);

        try {
          const response = await axios.post('/api/set_user_history',formData,{
             headers: {
              'Authorization': 'Bearer YOUR_TOKEN',
              'device_token': localStorage.getItem('device_token')
            }
          });
          
          if(response.data.status == 200){
            
              this.isSpinnerOpen = false;
              this.showToaster(response.data.massage);
            
          }else if(response.data.status == 400){

            this.showToaster(response.data.massage);
            this.$router.push('/splash');

          }else{
            this.isSpinnerOpen = false;
            this.showToaster(response.data.massage);
          }

        } catch (error) {
          
          this.isSpinnerOpen = false;
          this.showToaster(error);
        }

    },
    showToaster(msg){
      this.isToasterOpen = true;
      this.isToasterError = msg;
      setTimeout(() => {
        this.isToasterOpen = false;
      }, 2000);
    }
  },
  mounted() {
    this.get_home_page_listing();
  }
};
</script>