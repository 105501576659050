<template>
  <div class="spinner-border spinner-custom" :class="{ 'show': isSpinnerOpen }" role="status"><span class="visually-hidden">Loading...</span></div>

    <div class="d-flex justify-content-center align-items-center w-100 toast-header">
      <div class="toast" :class="{ 'show': isToasterOpen }">
        <div class="toast-body">{{isToasterError}}</div>
      </div>
    </div>

    <div class="wrap-template">
      <div class="login-top">
        <h1>Sign In</h1>
        <p>Hi, Welcome back</p>
      </div>  
    </div>

  <div class="wrap-template">
        
      <div class="login-input-div">
        
        <label class="login-lable">Phone No.</label>
        <input type="number" minlength="10" maxlength="10" required="" class="mobile_input" v-model="mobileNumber"  @input="validateMobileNumber">


        <div class="passcode-code-bar" :class="{ 'show': isShowPasscode}">
          <label class="login-lable">Passcode</label>
          <div class="passcode-code">
            <input type="number" min="0" max="9" v-model="passcode_1" maxlength="1" minlength="1"  v-on:keyup="$event.target.nextElementSibling.focus()" required="">
            <input type="number" min="0" max="9" v-model="passcode_2" maxlength="1" minlength="1"  v-on:keyup="$event.target.nextElementSibling.focus()">
            <input type="number" min="0" max="9" v-model="passcode_3" maxlength="1" minlength="1"  v-on:keyup="$event.target.nextElementSibling.focus()">
            <input type="number" min="0" max="9" v-model="passcode_4">
          </div>
        </div>
 

        <div class="send-btn-2 login-btn" :class="{ 'hide': isShowPasscode}" style="margin-top: 8%"  @click="getLoginCode()"><i class="fa fa-send"></i> WhatsApp Code</div>

        <div class="send-btn-2 login-btn-2" :class="{ 'show': isShowPasscode}" style="margin-top: 8%"  @click="getLoginWithPassCode()"><i class="fa fa-send"></i> Verify</div>

        
      
        <p v-if="mobileNumberError" class="error">{{ mobileNumberError }}</p>
      </div>

      <div class="passcode-bar" :class="{ 'hide': isShowPasscode}" @click="showPasscode">
        <p>I have already Passcoe</p>
      </div>

      <div class="passcode-bar-back" :class="{ 'show': isShowPasscode}" @click="hidePasscode">
        <p>Back to Login</p>
      </div>


    
  </div>
  
</template>


<script>
import axios from 'axios';
export default {
  data() {
    return {
      isSpinnerOpen:false,
      isToasterOpen:false,
      isToasterError:'',
      mobileNumber:'',
      mobileNumberError:'',
      passcode:'',
      passcode_1:'',
      passcode_2:'',
      passcode_3:'',
      passcode_4:'',
      isShowPasscode:false,
    };
  },
  methods: {
    showPasscode(){
      this.isShowPasscode = true;
      this.passcode_1 = '';
      this.passcode_2 = '';
      this.passcode_3 = '';
      this.passcode_4 = '';
    },
    hidePasscode(){
      this.isShowPasscode = false;
    },
    check_token_in_local_server(){
      //localStorage.setItem('device_token','490637012055');
      //localStorage.setItem('user_name','');
      var tokan_number  = localStorage.getItem('device_token');
      
      if(tokan_number != 0 && tokan_number != null){
        this.check_token_on_server(tokan_number);
      }

    },
    validateMobileNumber() {
      
      const phoneRegex = /^[0-9]{10}$/;
      if (!phoneRegex.test(this.mobileNumber)) {
        //this.mobileNumberError = 'Invalid mobile number.';
        //this.showToaster('Enter valid mobile number!.');
      } else {
        this.mobileNumberError = '';
      }
    },
    async getLoginCode() {
      
      if(this.mobileNumber != ''){
        const phoneRegex = /^[0-9]{10}$/; 
      
        if (phoneRegex.test(this.mobileNumber)) {
          
          this.mobileNumberError = ''; 
          this.isSpinnerOpen = true;
          
          try {
            const response = await axios.get('/api/milanlogin/'+this.mobileNumber);
            
            if(response.data.status == 200){

              // send code by whatsapp
              //var mn = '91'+this.mobileNumber;
              //var msg = 'Please send verifition code';
              //window.open('https://wa.me/'+mn+'?text='+msg, '_blank');
              
              this.isSpinnerOpen = false;
              this.showPasscode();
              //this.$router.push('/loginVerifyCode');

            }else{

              this.isSpinnerOpen = false;
              
              this.showToaster(response.data.massage);

            }
          } catch (error) {

            this.isSpinnerOpen = false;

            this.showToaster(error);

          }

        }else{
          //this.mobileNumberError = 'Invalid mobile number.';
          this.showToaster('Enter valid mobile number!.');
        }
      }else{
        this.showToaster('Please enter mobile number!.');
      }
    },
    async getLoginWithPassCode() {
      
      this.passcode = this.passcode_1 +''+ this.passcode_2 +''+ this.passcode_3 +''+ this.passcode_4;
      
      if (this.mobileNumber == '') {
        this.showToaster('Please enter mobile number!.');
        return false;
      }

      const phoneRegex = /^[0-9]{10}$/; 
      
      if (!phoneRegex.test(this.mobileNumber)) {
        this.showToaster('Enter valid mobile number!.');
        return false;
      }

      if (this.passcode >= 1000 && this.passcode <= 9999) {
      
        this.mobileNumberError = ''; 
        this.isSpinnerOpen = true;
      
        try {
          const response = await axios.get('/api/verify_otp/'+this.mobileNumber+'/'+this.passcode);
          
          if(response.data.status == 200){
            
           localStorage.setItem('device_token',response.data.data.device_token);
           
           if(response.data.data.name != '' && response.data.data.name != null){
            
            localStorage.setItem('user_name',response.data.data.name);

            this.$router.push('/home');

           }else{
            
              this.$router.push('/loginUserNamePage');

           }

          }else{

            this.isSpinnerOpen = false;
            this.showToaster(response.data.massage);

          }
        } catch (error) {
          
          this.isSpinnerOpen = false;
          
          this.showToaster(error);
        }

      }else{
        //this.mobileNumberError = 'Please 4 digit.';
        this.showToaster('Please check OTP');
      }
    },
    async check_token_on_server(tokan_number) {
      
      this.isSpinnerOpen = true;

      try {
        const response = await axios.get('/api/verifytoken',{
           headers: {
            'Authorization': 'Bearer YOUR_TOKEN',
            'device_token': tokan_number
          }
        });
        
        if(response.data.status == 200){
          
          localStorage.setItem('user_name',response.data.user_name);
          this.isSpinnerOpen = false;
          this.$router.push('/home');

        }else{

          localStorage.setItem('device_token','');
          
          this.isSpinnerOpen = false;

        }
      } catch (error) {
        
        this.isSpinnerOpen = false;
        alert(error);
      }
    },
    showToaster(msg){
      this.isToasterOpen = true;
      this.isToasterError = msg;
      setTimeout(() => {
        this.isToasterOpen = false;
      }, 2000);
    },
    gotoHome(){
      this.$router.push('/home');
    }
  },
  mounted() {
    //this.check_token_in_local_server();
  }
};
</script>